
import { defineComponent } from 'vue';
import TheTimeslotTable from '@/components/TheTimeslotTable.vue';
import TheScheduleTable from '@/components/TheScheduleTable.vue';
import TheScheduleUpdate from '@/components/TheScheduleUpdate.vue';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { ACTION } from '@/store/actions';
import { STATE } from '@/constants';

export default defineComponent({
    name: 'TimeslotSchedule',
    components: { TheTimeslotTable, TheScheduleTable, TheScheduleUpdate},
    data() {
        return {
            collapsedTimeslots: false,
            collapsedCancelledGame: false,
            filterSetName: '__timeslots__'
        }
    },
    computed: {
        canUpdateTimeslots(): boolean {
            return store.getters.isAdmin;
        },
        matchesSectionTitle(): string {
            return store.getters.isAdmin? 'Parties annulées': 'Mes parties annulées'
        },
        pendingSectionTitle(): string {
            return store.getters.isAdmin? 'Plages horaires': 'Demandes de reprise'
        }
    },
    methods: {
        toggleTimeslotTable(){
            this.collapsedTimeslots = !this.collapsedTimeslots;
        },
        toggleCancelledGameTable() {
            this.collapsedCancelledGame = !this.collapsedCancelledGame;
        },
        setScheduleFilters() {
            store.commit(MUTATION.RESET_SCHEDULE_FILTERS, this.filterSetName);
            store.commit(MUTATION.SET_CANCELLED_FILTER, {filterSet: this.filterSetName, cancelled: true});
            
                // Captain can only see his team match, admin can see everything
            
            if(store.getters.isCaptain) {
                store.commit(MUTATION.SET_TEAM_NAMES_FILTERS, {filterSet: this.filterSetName, teamNames: store.state.sessionInfo.perm.teams});
            }
        }
    },
    watch: {
        $route(to) {
            if (to.path === '/timeslots') this.setScheduleFilters();
        }
    },
    mounted() {
        if(store.state.fieldsRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_FIELDS);
        }
        this.setScheduleFilters();
        if(store.state.timeslotsRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_TIMESLOTS);
        }
    },
});
