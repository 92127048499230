
import { defineComponent } from 'vue';
import TheScheduleImportReport from '@/components/TheScheduleImportReport.vue';
import Datepicker from 'vue3-datepicker';
import FileUpload from './lib/FileUpload.vue';
import Checkbox from './Checkbox.vue';
import { availableTime, timezoneDate } from '@/utils';
import { TeamInterface } from '@/services/teams';
import { FieldInterface } from '@/services/fields';
import { store } from '@/store/store';
import { ACTION } from '@/store/actions';
import { STATE } from '@/constants';

export default defineComponent({
    name: 'Schedule',
    components: { TheScheduleImportReport, Datepicker, FileUpload, Checkbox },
    props: {
        matchUpdate: {
            required: false,
            default: true
        },
        fileImport: {
            required: false,
            default: true
        }
    },
    data() {
        return {
            addMatchDateSelected: new Date(Date.now()),
            addMatchTimeSelected: '12:00:00',
            addMatchFieldSelected: '',
            addMatchHomeSelected: '',
            addMatchAwaySelected: '',
            overwrite: false,
            stopOnError: true,
            fileSchedule: {
                fileName: null as string | null,
                fileBlob: null as File | null
            },
            importReportVisible: false
        };
    },
    computed: {
        matchAddValid(): boolean {
            return this.matchUpdate && (
                this.addMatchAwaySelected !== ''
                && this.addMatchHomeSelected !== ''
                && this.addMatchFieldSelected !== ''
            );
        },
        timeslotAddValid(): boolean {
            return !this.matchUpdate && this.addMatchFieldSelected !== ''
        },
        sortedTeams(): string[] {
            return store.getters.sortedTeams;
        },
        sortedFields(): string[] {
            return store.getters.sortedFields;
        },
        availableTime(): string[] {
            return availableTime(15);
        },
        fieldsLoading(): boolean {
            return store.state.fieldsRequestStatus.status === STATE.LOADING;
        },
        addMatchError(): boolean {
            return store.state.addMatchRequestStatus.status === STATE.ERR;
        },
        addMatchLoading(): boolean {
            return store.state.addMatchRequestStatus.status === STATE.LOADING;
        },
        addMatchReady(): boolean {
            return store.state.addMatchRequestStatus.status === STATE.DONE;
        },
        addMatchErrMsg(): string {
            return store.state.addMatchRequestStatus.msg;
        },
        hasImportReport(): boolean {
            return (
                store.state.addMatchReport.matchConflicts.length > 0
                || store.state.addMatchReport.missingFields.length > 0
                || store.state.addMatchReport.missingTeams.length > 0
                || store.state.addMatchReport.badMatchData.length > 0
            );
        }
    },
    methods: {
        fieldInfo(fieldName: string): FieldInterface {
            return store.getters.fieldInfo(fieldName);
        },
        teamInfo(teamName: string): TeamInterface {
            return store.getters.teamInfo(teamName);
        },
        addToSchedule() {
            const date = this.addMatchDateSelected;
            const [hh, mm, ss] = this.addMatchTimeSelected.split(':').map(t =>  {
                return parseInt(t)
            });
            date.setHours(hh, mm, ss, 0);
            if(this.matchUpdate) {
                store.dispatch(ACTION.ADD_MATCH, {
                    date: timezoneDate(date).toISOString(),
                    field: this.addMatchFieldSelected,
                    homeTeam: this.addMatchHomeSelected,
                    awayTeam: this.addMatchAwaySelected
                });
            }
            else {
                store.dispatch(ACTION.ADD_TIMESLOT, {
                    date: timezoneDate(date).toISOString(),
                    field: this.addMatchFieldSelected,
                });
            }
            this.addMatchFieldSelected = '';
            this.addMatchHomeSelected = '';
            this.addMatchAwaySelected = '';
        },
        importSchedule(payload: {fileBlob: File, fileName: string}) {
            this.fileSchedule = payload;
            if(!payload.fileBlob) {
                this.importReportVisible = false;
                return;
            }
            store.dispatch(ACTION.ADD_MATCH_FROM_FILE, {
                data: payload,
                opts: {
                    overwrite: this.overwrite,
                    stopOnError: this.stopOnError
                }
            });
        },
        toggleOverwrite(isToggle: boolean) {
            this.overwrite = isToggle;
        },
        toggleStopOnError(isToggle: boolean) {
            this.stopOnError = isToggle;
        },
        toggleImportReport() {
            this.importReportVisible = !this.importReportVisible;
        }
    }
});
