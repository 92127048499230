
import { defineComponent } from 'vue';
import TheAvailableTimeslots from '@/components/TheAvailableTimeslots.vue';
import { emptyMatchDef } from '@/utils';
import { store } from '@/store/store';
import { STATE } from '@/constants';
import { ACTION } from '@/store/actions';
import { MatchInterface } from '@/services/matches';

export default defineComponent({
    name: 'TheTimeslotTable',
    components: { TheAvailableTimeslots },
    data() {
        return {
            availSelectedTimeslotIx: -1,
            pendingSelectedTimeslotIx: -1
        };
    },
    props: {
        filterSetName: {
            type: String,
            required: true
        }
    },
    computed: {
        isAdmin() {
            return store.getters.isAdmin;
        },
        timeslotsLoading(): boolean {
            return store.state.timeslotsRequestStatus.status === STATE.LOADING;
        },
        emptyMatchDef(): MatchInterface {
           return emptyMatchDef();
        }
    },
    mounted() {
        if(store.state.timeslotsRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_TIMESLOTS);
        }
    }
});
