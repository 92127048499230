
import { defineComponent } from 'vue';
import { TImportScheduleReport } from '@/services/matches';
import { store } from '@/store/store';

export default defineComponent({
    name: 'TheScheduleImportReport',
    computed: {
        report(): TImportScheduleReport {
            return store.state.addMatchReport;
        }
    }
});
